import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#1772DC',
      main: '#0288d1',
    },
    secondary: {
      main: '#FA6400',
    },
    typography: {
      fontFamily: 'Open Sans, sans-serif',
    },
  },
});

export default theme;
