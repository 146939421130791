import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';

export const initialColorState = {
  green: 0,
  gold: 0,
  brick: 0,
  answersOrder: [],
  colorResult: '',
};

// Tie-breaker Weighting/Algorithm:
// If quiz results in a tie, double count Q #1.
// If double count of Q #1 doesn’t result in a tie break, double count both Q #1 and Q #2.
export const defineClassification = (state) => {
  const colorOccurrences = {};
  const stateCopy = cloneDeep(state);
  const answersOrder = cloneDeep(state.answersOrder);
  let count = 0;
  forEach(state, (val, key) => {
    if (key === 'green' || key === 'gold' || key === 'brick') {
      if (!colorOccurrences[val]) {
        colorOccurrences[val] = [key];
      } else {
        colorOccurrences[val].push(key);
      }
      count += val;
    }
  });
  // stops recursion if there are less than 5 color occurrences or more than 5 in the answersOrder array (should never happen)
  if (count < 4 || answersOrder.length > 5) {
    return null;
  }

  if (colorOccurrences['5']) {
    return colorOccurrences['5'][0];
  }
  if (colorOccurrences['4']) {
    return colorOccurrences['4'][0];
  }
  if (colorOccurrences['3']) {
    return colorOccurrences['3'][0];
  }

  stateCopy[answersOrder[0]] += 1;
  stateCopy.answersOrder.shift();
  return defineClassification(stateCopy);
};

export const colorReducer = (state, action) => {
  switch (action.type) {
    case 'green': {
      return {
        ...state,
        green: state.green + 1,
        answersOrder: [...state.answersOrder, action.type],
      };
    }
    case 'gold': {
      return {
        ...state,
        gold: state.gold + 1,
        answersOrder: [...state.answersOrder, action.type],
      };
    }
    case 'brick': {
      return {
        ...state,
        brick: state.brick + 1,
        answersOrder: [...state.answersOrder, action.type],
      };
    }
    case 'result': {
      return { ...state, colorResult: defineClassification(state) };
    }
    case 'reset':
      return initialColorState;
    default:
      return state;
  }
};
