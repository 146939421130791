export const QUESTION_NUMBER = 'QUESTION_NUMBER';
export const RESET = 'RESET';
export const RESULT = 'RESULT';

export const ENERGIZER = 'energizer';
export const PIONEER = 'pioneer';
export const CREATOR = 'creator';
export const WINNER = 'winner';
export const FIXER = 'fixer';
export const REALIST = 'realist';

export const GREEN = 'green';
export const GOLD = 'gold';
export const BRICK = 'brick';
