import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LargeText from 'components/LargeText';
import RegularText from 'components/RegularText';
import PaddingContainer from 'components/PaddingContainer';
import Image from 'components/Image';
// import LogosContainer from 'components/LogosContainer';

export default function RegistrationSuccess() {
  const history = useHistory();
  const location = useLocation();
  const [messageString, setMessageString] = useState(
    `We've sent you an email with a link to complete your registration.`
  );

  useEffect(() => {
    if (!location.state.emailData) {
      return history.push('/');
    }
    return setMessageString(
      `We've sent you an email to ${location.state.emailData.email} with a link to complete your registration.`
    );
  }, [location.state.emailData]);

  return (
    <div data-testid="SuccessPage" className="flex flex-col h-full">
      <div className="flex-1 flex-grow bg-blue-50 block">
        <div className="h-full w-full flex flex-col justify-center items-center py-3 px-3 md:px-20">
          <div className="ml-4 md:ml-10">
            <PaddingContainer>
              <Image
                imageUrl="https://res.cloudinary.com/hyetmyubn/image/upload/v1621437188/Brands%20for%20Good%20Quiz/BFG_successpage.svg"
                altTag="success page illustration"
              />
            </PaddingContainer>
          </div>
          <PaddingContainer>
            <LargeText content="Success!" />
          </PaddingContainer>
          <PaddingContainer>
            <RegularText content={messageString} />
            <div className="mt-4 border-b border-solid" />
          </PaddingContainer>
          <PaddingContainer>
            <RegularText content="If you are having trouble finding the email, please check your spam folder." />
          </PaddingContainer>
        </div>
      </div>
      {/* <LogosContainer /> */}
    </div>
  );
}
