import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import capitalize from 'lodash/capitalize';
import Image from 'components/Image';
import LargeText from 'components/LargeText';
import RegularText from 'components/RegularText';
import PaddingContainer from 'components/PaddingContainer';
// import LogosContainer from 'components/LogosContainer';
import SubmitForm from 'components/SubmitForm/SubmitForm';

import { QuizContext } from 'contexts/QuizContext';
import { answersData } from 'adapters/answersData';

export default function Results() {
  const { push } = useHistory();
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const { personaScore, colorScore } = useContext(QuizContext);
  const [personaText, setPersonaText] = useState('No persona available');
  const [description, setDescription] = useState('No description available');
  const [imageUrl, setImageUrl] = useState('');

  const setPersona = () => {
    if (personaScore.result.length > 0) {
      const article = personaScore.result === 'energizer' ? 'an' : 'a';
      const capitalizedPersona = capitalize(personaScore.result);
      setPersonaText(`You are ${article} ${capitalizedPersona}`);
      if (answersData[personaScore.result])
        setDescription(answersData[personaScore.result].description);
      setImageUrl(answersData[personaScore.result].imageUrl);
    }
  };

  useEffect(() => {
    if (personaScore.result === '') {
      return push(`/${search}`);
    }
    return setPersona();
  }, [personaScore]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 flex-grow block">
        <div className="h-full flex flex-col md:flex-row">
          <div className="h-full w-full flex flex-col justify-center items-center py-3 px-3 md:px-20">
            <PaddingContainer>
              <Image imageUrl={imageUrl} altTag="persona results illustration" />
            </PaddingContainer>
            <PaddingContainer>
              <LargeText content={personaText} colorString="#FA6400" />
            </PaddingContainer>
            <PaddingContainer>
              <RegularText content={description} />
            </PaddingContainer>
          </div>
          <div className="h-full w-full flex flex-col justify-center items-center py-3 px-3 md:px-20 bg-blue-50">
            <PaddingContainer>
              <SubmitForm
                personaResult={personaScore.result}
                colorResult={colorScore.colorResult}
                brand={queryParams.i}
              />
            </PaddingContainer>
          </div>
        </div>
      </div>
      {/* <LogosContainer /> */}
    </div>
  );
}
