export const initialPersonaState = {
  energizer: 0,
  pioneer: 0,
  creator: 0,
  winner: 0,
  fixer: 0,
  realist: 0,
  result: '',
};

export const determineHighestValue = (state) =>
  Object.keys(state).reduce((a, b) => (state[a] > state[b] ? a : b));

export const personaReducer = (state, action) => {
  switch (action.type) {
    case 'energizer':
      return { ...state, energizer: state.energizer + 1 };
    case 'pioneer':
      return { ...state, pioneer: state.pioneer + 1 };
    case 'creator':
      return { ...state, creator: state.creator + 1 };
    case 'winner':
      return { ...state, winner: state.winner + 1 };
    case 'fixer':
      return { ...state, fixer: state.fixer + 1 };
    case 'realist':
      return { ...state, realist: state.realist + 1 };
    case 'result':
      return {
        ...state,
        result: determineHighestValue(state),
      };
    case 'reset':
      return initialPersonaState;
    default:
      return state;
  }
};
