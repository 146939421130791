import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  largeText: {
    'font-family': 'Merriweather',
    'font-style': 'normal',
    'font-weight': '900',
    'line-height': '3.5rem',
    'letter-spacing': '0.75px',
    'text-align': 'center',
    'padding-right': '16px',
    'padding-left': '16px',
    opacity: '87%',
    [theme.breakpoints.down('sm')]: {
      'font-size': '26px',
      'line-height': '2.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      'font-size': '30px',
    },
    [theme.breakpoints.up('md')]: {
      'font-size': '34px',
    },
    [theme.breakpoints.up('lg')]: {
      'font-size': '38px',
    },
    [theme.breakpoints.up('xl')]: {
      'font-size': '46px',
    },
  },
}));

export default function LargeText({ content, colorString }) {
  const classes = useStyles();
  const [colorSelected, setColorSelected] = useState('#000E33');

  useEffect(() => {
    if (colorString.length > 0) setColorSelected(colorString);
  }, [colorString]);

  return (
    <div data-testid="LargeTextHolder">
      <div className={classes.largeText} style={{ color: colorSelected }}>
        <div data-testid="large-text__content">{content}</div>
      </div>
    </div>
  );
}

LargeText.defaultProps = {
  content: '',
  colorString: '',
};

LargeText.propTypes = {
  content: PropTypes.string,
  colorString: PropTypes.string,
};
