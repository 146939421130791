import T from 'prop-types';
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useHistory, useLocation } from 'react-router-dom';
import RegularText from 'components/RegularText';
import AlertToast from 'components/AlertToast';
import PaddingContainer from 'components/PaddingContainer';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Snackbar } from '@material-ui/core';
import authTokenAdapter from 'adapters/authTokenAdapter';
import submitResultsAdapter from 'adapters/submitResultsAdapter';

const useStyles = makeStyles((theme) => ({
  emailField: {
    border: '1px black solid',
    height: '64px',
    'border-radius': '8px',
    'padding-left': '20px',
    [theme.breakpoints.down('sm')]: {
      width: '210px',
      height: '48px',
      fontSize: '.7rem',
    },
    [theme.breakpoints.up('md')]: {
      width: '300px',
      fontSize: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
      width: '420px',
      fontSize: '1rem',
    },
  },
  submitButton: {
    height: '64px',
    borderRadius: '8px',
    fontSize: '1.2rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',
    textTransform: 'uppercase',
    marginTop: '28px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      fontWeight: '900',
      'box-shadow': '14px 16px 25px -17px #000E3340',
      border: `2px solid ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '210px',
      height: '48px',
      fontSize: '.7rem',
    },
    [theme.breakpoints.up('md')]: {
      width: '300px',
      fontSize: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
      width: '420px',
      fontSize: '1rem',
    },
  },
  '@keyframes fadein': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  blinkText: {
    animationName: '$fadein',
    animationTimingFunction: 'linear',
    animationDuration: '1s',
  },
}));

export default function SubmitForm({ personaResult, colorResult, brand }) {
  const history = useHistory();
  const { search } = useLocation();
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (emailData) => {
    ReactGA.event({
      category: 'Button',
      action: `Submit email: begin`,
    });
    setSubmitting(true);
    const token = await authTokenAdapter();

    if (token) {
      const params = {
        persona: personaResult,
        classification: colorResult,
        email: emailData.email,
        token,
        brand,
      };
      const response = await submitResultsAdapter({ params, token });
      if (response) {
        ReactGA.event({
          category: 'Success email submission',
          action: `Submit email: success`,
        });
        return history.push(`/success${search}`, { emailData });
      }
      setSubmitting(false);
      return setOpen(true);
    }
    setSubmitting(false);
    return setOpen(true);
  };

  return (
    <div className="text-center">
      {submitting ? (
        <div data-testid="submitting__text" className={`h-full ${classes.blinkText}`}>
          <RegularText content="Submitting..." />
        </div>
      ) : (
        <div>
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <AlertToast severity="error">Something went wrong</AlertToast>
          </Snackbar>
          <div className="h-full items-center justify-around flex flex-col">
            <PaddingContainer>
              <RegularText
                content="Sign up to see sustainable actions customized just for you."
                selectedWeight="600"
              />
            </PaddingContainer>
            <form
              data-testid="email__form"
              onSubmit={handleSubmit(onSubmit)}
              className="items-center justify-around flex flex-col"
            >
              <input
                data-testid="submit__input"
                className={classes.emailField}
                id="email"
                name="email"
                placeholder="Email*"
                type="email"
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'invalid email address',
                  },
                })}
              />

              {errors.email && (
                <div className="mt-6" data-testid="error__msg">
                  Please enter a valid email
                </div>
              )}
              <Button data-testid="submit__button" type="submit" className={classes.submitButton}>
                SIGN UP
              </Button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

SubmitForm.defaultProps = {
  personaResult: '',
  colorResult: '',
  brand: '',
};

SubmitForm.propTypes = {
  personaResult: T.string,
  colorResult: T.string,
  brand: T.string,
};
