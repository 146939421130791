import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import c from 'classnames';
import cb from 'classnames/bind';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '608px',
    height: '80px',
    border: '1px solid',
    outline: '0 !important',
    backgroundColor: 'white',
    'border-color': theme.palette.grey[300],
    'box-sizing': 'border-box',
    'border-radius': '8px',
    'font-size': '24px',
    'font-style': 'italic',
    'font-weight': '500',
    'line-height': '32px',
    'letter-spacing': '0px',
    'text-align': 'center',
    'margin-bottom': '20px',
    color: '#000E33',
    opacity: '87%',
    textTransform: 'none',
    padding: '16px',
    '&:hover': {
      'font-weight': '700',
      'background-color': '#fff',
      border: '1px solid',
      'border-color': theme.palette.primary.light,
      'box-shadow': '14px 16px 25px -17px #000E3340',
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      'font-size': '14px',
    },
    [theme.breakpoints.down('md')]: {
      width: '300px',
      'font-size': '16px',
    },
  },
  active: {
    'font-weight': '700',
    border: '2px solid',
    color: theme.palette.primary.light,
    'border-color': theme.palette.primary.light,
    '&:hover': {
      border: '2px solid',
      color: theme.palette.primary.light,
      'border-color': theme.palette.primary.light,
    },
  },
  img: {
    'border-radius': '8px',
  },
  imageButton: {
    outline: '0 !important',
    border: '1px solid',
    margin: '0 7px 20px 7px',
    'border-color': theme.palette.grey[600],
    padding: '8px',
    backgroundColor: theme.palette.common.white,
    'border-radius': '8px',
    '&:hover': {
      border: '1px solid',
      'border-color': theme.palette.primary.light,
      '-moz-transform': 'translateY(-4px)',
      '-ms-transform': 'translateY(-4px)',
      '-o-transform': 'translateY(-4px)',
      '-webkit-transform': 'translateY(-4px)',
      transform: 'translateY(-4px)',
    },
  },
  imageActive: {
    border: '2px solid',
    'border-color': theme.palette.primary.light,
    'box-shadow': `14px 16px 25px -17px ${theme.palette.grey[600]}`,
    '&:hover': {
      border: '2px solid',
      'border-color': theme.palette.primary.light,
      '-moz-transform': 'translateY(0px)',
      '-ms-transform': 'translateY(0px)',
      '-o-transform': 'translateY(0px)',
      '-webkit-transform': 'translateY(0px)',
      transform: 'translateY(0px)',
    },
  },
}));

export default function OptionButton({
  optionData,
  handleClick,
  chosenOption,
  classificationSelected,
}) {
  const classes = useStyles();
  const [toggleClass, setToggleClass] = useState(false);
  const [imageWidth, setImageWidth] = useState('440');

  useEffect(() => {
    setToggleClass(chosenOption === optionData.persona);
    if (classificationSelected === 'green') {
      setImageWidth('280');
    }
  }, [chosenOption, optionData.persona, classificationSelected]);

  const cx = cb.bind({
    active: classes.active,
    imageActive: classes.imageActive,
  });

  const textButtonClass = cx(classes.button, { active: toggleClass });
  const imageButtonClass = cx(classes.imageButton, { imageActive: toggleClass });

  if (optionData.imageUrl) {
    return (
      <button
        type="submit"
        onClick={() => handleClick(optionData.persona)}
        className={c(imageButtonClass)}
        data-testid="question-option__img-button"
      >
        <img
          src={optionData.imageUrl}
          className={classes.img}
          alt="option"
          height="400"
          width={imageWidth}
        />
      </button>
    );
  }
  return (
    <Button
      data-testid="question-option__button"
      onClick={() => handleClick(optionData.persona)}
      className={c(textButtonClass)}
    >
      {optionData.answer}
    </Button>
  );
}

OptionButton.propTypes = {
  optionData: T.shape({ answer: T.string, persona: T.string, imageUrl: T.string }),
  handleClick: T.func.isRequired,
  chosenOption: T.string,
  classificationSelected: T.string,
};

OptionButton.defaultProps = {
  optionData: { persona: '', imageUrl: '' },
  chosenOption: '',
  classificationSelected: '',
};
