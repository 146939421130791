import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: '24px 24px 24px 24px ',
    [theme.breakpoints.down('md')]: {
      padding: '12px 12px 12px 12px',
    },
  },
}));

export default function PaddingContainer({ children }) {
  const classes = useStyles();
  return (
    <div data-testid="padding__container" className={classes.padding}>
      {children}
    </div>
  );
}

PaddingContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
