import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { ThemeProvider } from '@material-ui/core/styles';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Home from 'pages/Home';
import QuizContextProvider from 'contexts/QuizContext';
import Question from 'pages/Question';
import Results from 'pages/Results';
import RegistrationSuccess from 'pages/RegistrationSuccess';
import theme from './styles';
import 'tailwind.css';

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div id="container" className="flex flex-col h-screen">
      <Router>
        <Header className="flex" />
        <ThemeProvider theme={theme}>
          <div id="main" className="flex flex-grow">
            <main className="flex-1 overflow-y-auto overflow-x-hidden">
              <Switch>
                <QuizContextProvider>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route path="/success">
                    <RegistrationSuccess />
                  </Route>
                  <Route path="/quiz">
                    <Question />
                  </Route>
                  <Route path="/results">
                    <Results />
                  </Route>
                  <Route
                    path="*"
                    render={({ location }) => {
                      if (location.search.includes('?i=')) {
                        return <Redirect to={`/${location.search}`} />;
                      }
                      return <Redirect to="/" />;
                    }}
                  />
                </QuizContextProvider>
              </Switch>
            </main>
          </div>
        </ThemeProvider>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
