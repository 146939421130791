import React, { useReducer, createContext } from 'react';
import T from 'prop-types';
import { personaReducer, initialPersonaState } from '../reducers/personaReducer/personaReducer';
import { colorReducer, initialColorState } from '../reducers/colorReducer/colorReducer';
import { initialQuestionState, questionReducer } from '../reducers/questionReducer/questionReducer';

export const QuizContext = createContext();
export const QuizContextProvider = ({ children }) => {
  const [questionState, dispatchQuestion] = useReducer(questionReducer, initialQuestionState);
  const [colorScore, dispatchColor] = useReducer(colorReducer, initialColorState);
  const [personaScore, dispatchPersona] = useReducer(personaReducer, initialPersonaState);

  const value = {
    colorScore,
    dispatchColor,
    personaScore,
    dispatchPersona,
    questionState,
    dispatchQuestion,
  };

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};

export default QuizContextProvider;

QuizContextProvider.propTypes = {
  children: T.node.isRequired,
};
