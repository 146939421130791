import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { QuizContext } from 'contexts/QuizContext';
import OptionButton from 'components/OptionButton';
import NextButton from 'components/NextButton';
import PaddingContainer from 'components/PaddingContainer';
import LargeText from 'components/LargeText';
import Image from 'components/Image';
import Progress from 'components/Progress';
import c from 'classnames';
import cb from 'classnames/bind';
import { QUESTION_NUMBER } from 'contexts/reducers/constants';

export default function Question() {
  const { search } = useLocation();
  const {
    dispatchColor,
    colorScore,
    dispatchPersona,
    questionState: { questionNumber, currentQuestion, shuffledOptions },
    dispatchQuestion,
  } = useContext(QuizContext);
  const history = useHistory();
  const [chosenOption, setChosenOption] = useState('');
  const answersCompleted = questionNumber;
  const cx = cb.bind({ landscapeOrientation: 'flex flex-col' });
  const orientationClass = cx('text-center', { landscapeOrientation: questionNumber !== 7 });

  // When the user uses the back button it goes back to /
  window.onpopstate = () => history.push('/');

  useEffect(() => {
    // reset all the reducers on landing page or page 1
    if (questionNumber === 0 || questionNumber === 1) {
      dispatchColor({ type: 'reset' });
      dispatchPersona({ type: 'reset' });
      dispatchQuestion({ type: 'reset' });
    }

    if (questionNumber === 0) {
      return history.push(`/${search}`);
    }

    return dispatchQuestion({
      type: QUESTION_NUMBER,
      payload: { questionNumber, colorResult: colorScore.colorResult },
    });
  }, [questionNumber]);

  const handleNextQuestion = () => {
    const nextQuestion = questionNumber + 1;
    let event = `question${nextQuestion}`;
    if (event === 'question9') {
      event = 'results';
    }
    ReactGA.event({
      category: 'Button',
      action: `go to next page: ${event}`,
      label: event,
    });

    if (questionNumber < 6) {
      dispatchColor({ type: chosenOption });
      dispatchColor({ type: 'result' });
      dispatchQuestion({
        type: QUESTION_NUMBER,
        payload: { questionNumber: nextQuestion, colorResult: colorScore.colorResult },
      });
      return setChosenOption('');
    }
    if (questionNumber < 8 && questionNumber > 5) {
      dispatchQuestion({
        type: QUESTION_NUMBER,
        payload: { questionNumber: nextQuestion, colorResult: colorScore.colorResult },
      });
      dispatchPersona({ type: chosenOption });
      return setChosenOption('');
    }

    dispatchPersona({ type: 'result' });
    setChosenOption('');
    history.push(`/results${search}`);
    return setChosenOption('');
  };

  const handleClick = (persona) => {
    setChosenOption(persona);
  };

  return (
    <div className="flex flex-col justify-center items-center h-full px-5 bg-blue-50">
      {questionNumber !== 7 && (
        <PaddingContainer>
          <Image imageUrl={currentQuestion.imageUrl} altTag="question illustration" />
        </PaddingContainer>
      )}
      <PaddingContainer>
        <LargeText content={currentQuestion.question} />
      </PaddingContainer>
      <div className={c(orientationClass)}>
        {shuffledOptions.map((option) => (
          <OptionButton
            key={option.persona}
            optionData={option}
            handleClick={handleClick}
            chosenOption={chosenOption}
            classificationSelected={currentQuestion.subClassification}
          />
        ))}
      </div>
      <NextButton disabled={chosenOption.length} handleClick={handleNextQuestion} />
      <PaddingContainer>
        <Progress location={answersCompleted} />
      </PaddingContainer>
    </div>
  );
}
