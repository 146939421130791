import React from 'react';
import T from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import c from 'classnames';
import times from 'lodash/times';

const useStyles = makeStyles((theme) => ({
  dot: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    'border-radius': '8px',
    border: `1px solid ${theme.palette.grey[800]}`,
    'margin-right': '3px',
  },
  darkDot: {
    'background-color': '#bbb',
  },
}));

export default function Progress({ location }) {
  const classes = useStyles();

  const dots = [];
  times(8, (i) => {
    dots.push(
      <div
        data-testid="progressCircle"
        key={i}
        className={c(classes.dot, location > i ? classes.darkDot : '')}
      />
    );
  });

  return (
    <div data-testid="progress-holder" className={c('box flex', classes.margin)}>
      {dots}
    </div>
  );
}

Progress.propTypes = {
  location: T.number.isRequired,
};
