import React from 'react';
import T from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  image: {
    'max-width': '350px',
    'max-height': '350px',

    [theme.breakpoints.down('sm')]: {
      width: '168px',
      height: '168px',
    },
    [theme.breakpoints.up('md')]: {
      width: '256px',
      height: '256px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '272px',
      height: '272px',
    },
  },
}));

export default function Image({ imageUrl, altTag }) {
  const classes = useStyles();
  return <img data-testid="imageHolder" src={imageUrl} alt={altTag} className={classes.image} />;
}

Image.defaultProps = {
  imageUrl: '',
  altTag: '',
};

Image.propTypes = {
  imageUrl: T.string,
  altTag: T.string,
};
