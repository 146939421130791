import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import NextButton from 'components/NextButton';
import LargeText from 'components/LargeText';
import Image from 'components/Image';
import PaddingContainer from 'components/PaddingContainer';
// import LogosContainer from 'components/LogosContainer';
import { QuizContext } from 'contexts/QuizContext/QuizContext';
import { QUESTION_NUMBER, RESET } from 'contexts/reducers/constants';

export default function Home() {
  const { search } = useLocation();
  const { dispatchQuestion, dispatchColor } = useContext(QuizContext);
  useEffect(() => {
    dispatchColor({ type: RESET });
  }, []);
  const history = useHistory();
  const handleStartQuiz = () => {
    const event = `question1`;
    ReactGA.event({
      category: 'Button',
      action: `go to next page: ${event}`,
      label: event,
    });
    dispatchQuestion({
      type: QUESTION_NUMBER,
      payload: { questionNumber: 1 },
    });
    history.push(`/quiz${search}`);
  };

  return (
    <div data-testid="Home" className="flex flex-col h-full">
      <div className="flex-1 flex-grow bg-blue-50 block">
        <div className="flex h-full">
          <div className="mb-auto mt-auto">
            <div className="flex w-screen mb-auto justify-center">
              <div className="flex flex-col px-6 md:px-28">
                <div className="flex mb-auto justify-center">
                  <Image
                    imageUrl="https://res.cloudinary.com/hyetmyubn/image/upload/v1621437182/Brands%20for%20Good%20Quiz/BFG_LandingPage.svg"
                    altTag="first page illustration"
                  />
                </div>
                <PaddingContainer>
                  <LargeText content="Small steps taken by many can make a big impact. Take this quiz to reveal your unique Superpower that helps bring everyone together to create a more sustainable future." />
                </PaddingContainer>
                <div className="flex mb-auto justify-center">
                  <div className="mb-12">
                    <NextButton text="LET'S GO!" handleClick={handleStartQuiz} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <LogosContainer /> */}
    </div>
  );
}
