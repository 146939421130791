import axios from 'axios';

// eslint-disable-next-line consistent-return
const submitResultsAdapter = async ({ params, token }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.token}`,
    },
  };

  const body = {
    classification: params.classification,
    persona: params.persona,
    email: params.email,
    brand: params.brand || 'Unaffiliated',
  };

  try {
    const result = await axios.post(
      'https://livegood.wespire.com/api/brandsforgoodquiz/sign_up',
      body,
      config
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export default submitResultsAdapter;
