import axios from 'axios';

// eslint-disable-next-line consistent-return
const authTokenAdapter = async () => {
  try {
    const result = await axios('https://livegood.wespire.com/api/brandsforgoodquiz/token');

    return result.data;
  } catch (error) {
    console.warning(error);
    return false;
  }
};

export default authTokenAdapter;
