import React from 'react';

const linkOptions = [
  { name: 'About', link: 'https://www.wespire.com/wespire-news/' },
  { name: 'Contact', link: 'mailto:support@wespire.com' },
  { name: 'Terms', link: 'https://www.wespire.com/terms-of-service/' },
  { name: 'Privacy', link: 'https://www.wespire.com/privacy-policy/' },
];

export default function Footer() {
  const currentYear = new Date().getFullYear().toString().substr(-2);
  return (
    <footer data-testid="footer" className="bg-blue-100 text-sm">
      <div className="mx-6 my-3">
        <div className="md:flex justify-between">
          <div className="md:flex justify-between">
            <div className="flex items-center py-0.5 md:py-2">
              <div>Powered by</div>
              <a href="https://www.wespire.com" target="_blank" rel="noreferrer">
                <img
                  data-testid="logoHolder"
                  className="ml-3 mr-5 h-8 pt-0.5"
                  src="https://res.cloudinary.com/hyetmyubn/image/upload/v1571254969/static/default_brand_logo.png"
                  alt="company logo"
                />
              </a>
            </div>
            <div className="flex flex-wrap items-center py-0.5 md:py-2">
              {linkOptions.map((option, idx) => (
                <div key={option.name} className="flex">
                  <a className="pr-3 " href={option.link} target="_blank" rel="noreferrer">
                    {option.name}
                  </a>
                  {linkOptions.length - 1 !== idx && <div className="pr-3">&bull;</div>}
                </div>
              ))}
            </div>
          </div>
          <div className="md:flex items-center py-0.5 md:py-2">
            <div>©2010-{currentYear} WeSpire, Inc. All rights reserved</div>
          </div>
        </div>
      </div>
    </footer>
  );
}
