import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import c from 'classnames';
import cb from 'classnames/bind';

const useStyles = makeStyles((theme) => ({
  button: {
    height: '64px',
    width: '400px',
    borderRadius: '8px',
    fontSize: '1.2rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '1.75rem',
    letterSpacing: '0px',
    textAlign: 'left',
    textTransform: 'uppercase',
    marginTop: '10px',
    border: `2px solid white`,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      fontWeight: '900',
      'box-shadow': '14px 16px 25px -17px #000E3340',
      border: `2px solid ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      height: '48px',
      fontSize: '.7rem',
    },
    [theme.breakpoints.down('md')]: {
      width: '200px',
      fontSize: '1rem',
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.secondary.main,
  },
}));

export default function NextButton({ text, handleClick, disabled }) {
  const classes = useStyles();
  const [toggleClass, setToggleClass] = useState(false);

  const cx = cb.bind({
    button: classes.button,
    disabled: classes.disabled,
  });
  const classNameButton = cx('button', { disabled: toggleClass });

  useEffect(() => {
    setToggleClass(disabled <= 0);
  }, [disabled]);

  return (
    <Button
      disabled={disabled <= 0}
      onClick={handleClick}
      variant="outlined"
      className={c(classNameButton)}
      data-testid="question__next-button"
    >
      {text}
    </Button>
  );
}

NextButton.propTypes = {
  text: T.string,
  handleClick: T.func,
  disabled: T.number,
};

NextButton.defaultProps = {
  text: 'Next Question',
  handleClick: () => {},
  disabled: 1,
};
