import shuffle from 'lodash/shuffle';
import questionsData from 'adapters/questionsData';
import { QUESTION_NUMBER, RESET } from '../constants';

export const initialQuestionState = {
  questionNumber: 0,
  currentQuestion: questionsData.colorSet[0],
  shuffledOptions: [],
};

export const determineQuestion = (questionSet, colorResult) =>
  questionSet.find((question) => question.subClassification === colorResult);

export const calculateAndShuffleQuestion = (personaQuestionGroup, payload) => {
  const calculatedQuestion = determineQuestion(personaQuestionGroup, payload);
  const shuffledOptions = shuffle(calculatedQuestion.options);
  return {
    shuffledOptions,
    calculatedQuestion,
  };
};

export const questionReducer = (state, action) => {
  switch (action.type) {
    case QUESTION_NUMBER: {
      if (action.payload.questionNumber < 6) {
        const currentQuestion = questionsData.colorSet[action.payload.questionNumber - 1];
        const shuffledOptions = shuffle(currentQuestion.options);

        return {
          ...state,
          currentQuestion,
          shuffledOptions,
          questionNumber: action.payload.questionNumber,
        };
      }
      const { shuffledOptions, calculatedQuestion } = calculateAndShuffleQuestion(
        questionsData[action.payload.questionNumber],
        action.payload.colorResult
      );
      return {
        ...state,
        currentQuestion: calculatedQuestion,
        shuffledOptions,
        questionNumber: action.payload.questionNumber,
      };
    }
    case RESET: {
      return initialQuestionState;
    }
    default:
      return state;
  }
};
