import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  regularText: {
    'font-style': 'normal',
    fontWeight: '400',
    'line-height': '1.5',
    'letter-spacing': '0.75px',
    'text-align': 'center',
    'white-space': 'pre-line',
    opacity: '87%',
    [theme.breakpoints.down('sm')]: {
      'font-size': '14px',
    },
    [theme.breakpoints.up('sm')]: {
      'font-size': '16px',
    },
    [theme.breakpoints.up('md')]: {
      'font-size': '18px',
    },
    [theme.breakpoints.up('lg')]: {
      'font-size': '20px',
    },
    [theme.breakpoints.up('xl')]: {
      'font-size': '22px',
    },
  },
}));

export default function RegularText({ content, colorString, selectedWeight }) {
  const classes = useStyles();
  const [colorSelected, setColorSelected] = useState('#000E33');

  useEffect(() => {
    if (colorString) setColorSelected(colorString);
  }, [colorString, colorSelected]);

  return (
    <div data-testid="RegularTextHolder">
      <div
        className={classes.regularText}
        style={{ color: colorSelected, fontWeight: selectedWeight }}
      >
        <div data-testid="regular-text__content">{content}</div>
      </div>
    </div>
  );
}

RegularText.defaultProps = {
  content: '',
  colorString: '',
  selectedWeight: '500',
};

RegularText.propTypes = {
  content: PropTypes.string,
  colorString: PropTypes.string,
  selectedWeight: PropTypes.string,
};
