export const answersData = {
  energizer: {
    description:
      'This means you’re an easy-going optimist, driven by a desire to experience everything life has to offer. You’ve got your finger on the pulse and you’re highly empathetic. You tend to really ‘get’ people, and because of that, people are drawn to you. You enjoy seeking out new experiences, meeting new people and try not to take life too seriously. Your Superpower is your ability to naturally network and mobilize others to join in for a good cause.',
    persona: 'energizer',
    imageUrl:
      'https://res.cloudinary.com/hyetmyubn/image/upload/v1621437182/Brands%20for%20Good%20Quiz/BFG_energizer.svg',
  },
  pioneer: {
    description:
      'This means you are a visionary thinker who is driven to innovate and always improve. You like to explore, push your own boundaries and challenge the status quo. You value openness and seek connection with others and the world around you. Your Superpower is your ability to trailblaze and set the course for others to follow.',
    persona: 'pioneer',
    imageUrl:
      'https://res.cloudinary.com/hyetmyubn/image/upload/v1621437181/Brands%20for%20Good%20Quiz/BFG_pioneer.svg',
  },
  creator: {
    description:
      'This means you are a passionate campaigner driven by a deep sense of purpose. You practice what you preach, valuing authenticity, accountability, and self improvement. You are knowledgeable about many topics, have a unique point of view and enjoy connecting the dots. Your Superpower is your conscientious leadership that holds yourself and others accountable to creating a better world.',
    persona: 'creator',
    imageUrl:
      'https://res.cloudinary.com/hyetmyubn/image/upload/v1621437182/Brands%20for%20Good%20Quiz/BFG_creator.svg',
  },
  winner: {
    description:
      'This means you enjoy the spirit of competition, while always having a winning game plan up your sleeve. Your recipe for success includes high ambitions, with a strategic, can-do attitude, partnered with a smart, entrepreneurial and goal-oriented mind-set. Your Superpower is your ability to turn dreams into reality, by always doing what you set your mind to, while also serving as an inspiration to others.',
    persona: 'winner',
    imageUrl:
      'https://res.cloudinary.com/hyetmyubn/image/upload/v1621437189/Brands%20for%20Good%20Quiz/BFG_winner.svg',
  },
  fixer: {
    description:
      'This means you are a hometown hero driven by a duty to serve others. You dream big, and work hard to create a better world for yourself and others. While you like big ideas, you prefer to act in small, but meaningful ways. Your Superpower is your ability to make positive contributions to your community and its valued traditions.',
    persona: 'fixer',
    imageUrl:
      'https://res.cloudinary.com/hyetmyubn/image/upload/v1621437182/Brands%20for%20Good%20Quiz/BFG_fixer.svg',
  },
  realist: {
    description:
      'This means you are determined and love doing things your own way. You are fiercely individual and self-reliant, also making you efficiently thrifty and resilient. You value simplicity and enjoy routine with a preferred style of doing things that are proven effective. Your Superpower is rational, independent thinking, and acting with common-sense to make life better.',
    persona: 'realist',
    imageUrl:
      'https://res.cloudinary.com/hyetmyubn/image/upload/v1621437189/Brands%20for%20Good%20Quiz/BFG_realist.svg',
  },
};
export default answersData;
