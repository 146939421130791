const questionData = {
  colorSet: [
    {
      question: 'What’s most likely to be under your yearbook photo?',
      options: [
        { answer: 'Going against the grain', persona: 'green' },
        { answer: "Won't stop till I reach the top", persona: 'gold' },
        { answer: 'Most likely to stay close to home', persona: 'brick' },
      ],
      imageUrl:
        'https://res.cloudinary.com/hyetmyubn/image/upload/v1621965559/Brands%20for%20Good%20Quiz/question1.svg',
    },
    {
      question: 'Which book are you most likely to pick up?',
      options: [
        { answer: 'Discovering your Purpose', persona: 'green' },
        { answer: 'How to Achieve your Dreams', persona: 'gold' },
        { answer: '10 Rules for Living Right', persona: 'brick' },
      ],
      imageUrl:
        'https://res.cloudinary.com/hyetmyubn/image/upload/v1621965559/Brands%20for%20Good%20Quiz/question2.svg',
    },
    {
      question: 'What do you find to be more of a pet peeve?',
      options: [
        { answer: 'People who hang on to the past', persona: 'green' },
        { answer: 'People who take things too seriously', persona: 'gold' },
        { answer: "People who don't respect the past", persona: 'brick' },
      ],
      imageUrl:
        'https://res.cloudinary.com/hyetmyubn/image/upload/v1621965559/Brands%20for%20Good%20Quiz/question3.svg',
    },
    {
      question: 'You’re now a big shot in your field. What’s your top tip?',
      options: [
        { answer: 'Take the road less travelled', persona: 'green' },
        { answer: 'Network. Network. Network.', persona: 'gold' },
        { answer: 'There are no shortcuts', persona: 'brick' },
      ],
      imageUrl:
        'https://res.cloudinary.com/hyetmyubn/image/upload/v1621965559/Brands%20for%20Good%20Quiz/question4.svg',
    },
    {
      question: 'Which phrase best describes the clothes in your wardrobe?',
      options: [
        { answer: 'Well-made', persona: 'green' },
        { answer: 'Stylish', persona: 'gold' },
        { answer: 'Good value', persona: 'brick' },
      ],
      imageUrl:
        'https://res.cloudinary.com/hyetmyubn/image/upload/v1621965559/Brands%20for%20Good%20Quiz/question5.svg',
    },
  ],
  6: [
    {
      question: 'Choose between the two:',
      options: [
        { answer: 'Bespoke expedition with a local, expert guide', persona: 'pioneer' },
        { answer: 'Volunteer trip and homestay to help a local community', persona: 'creator' },
      ],
      subClassification: 'green',
      imageUrl:
        'https://res.cloudinary.com/hyetmyubn/image/upload/v1621965559/Brands%20for%20Good%20Quiz/question6.svg',
    },
    {
      question: 'Choose between the two:',
      options: [
        { answer: 'Luxury villa rental, somewhere with a vibe', persona: 'energizer' },
        { answer: 'A relaxing, all-inclusive resort stay.', persona: 'winner' },
      ],
      subClassification: 'gold',
      imageUrl:
        'https://res.cloudinary.com/hyetmyubn/image/upload/v1621965559/Brands%20for%20Good%20Quiz/question6.svg',
    },
    {
      question: 'Choose between the two:',
      options: [
        { answer: 'Family road trip', persona: 'fixer' },
        { answer: 'Anything to get away from the daily grind', persona: 'realist' },
      ],
      subClassification: 'brick',
      imageUrl:
        'https://res.cloudinary.com/hyetmyubn/image/upload/v1621965559/Brands%20for%20Good%20Quiz/question6.svg',
    },
  ],
  7: [
    {
      question: 'Choose between the two:',
      options: [
        {
          answer: 'Epic futuristic utopia',
          persona: 'pioneer,',
          imageUrl:
            'https://res.cloudinary.com/hyetmyubn/image/upload/v1620914482/Brands%20for%20Good%20Quiz/BFG_Question7_p.jpg',
        },
        {
          answer: 'Back-to-basics living off the land universalism',
          persona: 'creator',
          imageUrl:
            'https://res.cloudinary.com/hyetmyubn/image/upload/v1620914499/Brands%20for%20Good%20Quiz/BFG_Question7_c.jpg',
        },
      ],
      subClassification: 'green',
    },
    {
      question: 'Choose between the two:',
      options: [
        {
          answer: 'Ostentatious mega yacht',
          persona: 'energizer',
          imageUrl:
            'https://res.cloudinary.com/hyetmyubn/image/upload/v1620914419/Brands%20for%20Good%20Quiz/BFG_Question7_e.jpg',
        },
        {
          answer: 'Kennedy family style sailing yacht',
          persona: 'winner',
          imageUrl:
            'https://res.cloudinary.com/hyetmyubn/image/upload/v1620912961/Brands%20for%20Good%20Quiz/BFG_Question7_w.jpg',
        },
      ],
      subClassification: 'gold',
    },
    {
      question: 'Choose between the two:',
      options: [
        {
          answer: 'Cul-de-sac',
          persona: 'fixer',
          imageUrl:
            'https://res.cloudinary.com/hyetmyubn/image/upload/v1620914434/Brands%20for%20Good%20Quiz/BFG_Question7_f.jpg',
        },
        {
          answer: 'Isolated, self-sufficient home',
          persona: 'realist',
          imageUrl:
            'https://res.cloudinary.com/hyetmyubn/image/upload/v1620912973/Brands%20for%20Good%20Quiz/BFG_Question7_r.jpg',
        },
      ],
      subClassification: 'brick',
    },
  ],
  8: [
    {
      question: 'You’ve been asked to donate to an NGO, what’s your first question?',
      options: [
        { answer: 'What are their values?', persona: 'creator' },
        { answer: 'What do they aim to achieve?', persona: 'pioneer' },
      ],
      subClassification: 'green',
      imageUrl:
        'https://res.cloudinary.com/hyetmyubn/image/upload/v1621965562/Brands%20for%20Good%20Quiz/question8_pioneer.svg',
    },
    {
      question:
        'You’re shopping online and see exactly what you want, but it’s a little pricey. What do you do?',
      options: [
        { answer: 'Time to get saving', persona: 'winner' },
        { answer: 'Credit card to the rescue', persona: 'energizer' },
      ],
      subClassification: 'gold',
      imageUrl:
        'https://res.cloudinary.com/hyetmyubn/image/upload/v1621965562/Brands%20for%20Good%20Quiz/question8_prospector.svg',
    },
    {
      question: 'Which link would you click on first?',
      options: [
        { answer: 'Seven must-have items for your go-bag', persona: 'realist' },
        { answer: 'Seven traditions we must bring back', persona: 'fixer' },
      ],
      subClassification: 'brick',
      imageUrl:
        'https://res.cloudinary.com/hyetmyubn/image/upload/v1621965561/Brands%20for%20Good%20Quiz/question8_bricks.svg',
    },
  ],
};

export default questionData;
