import React from 'react';

export default function Header() {
  return (
    <nav
      data-testid="HeaderHolder"
      className="w-screen bg-white filter drop-shadow-sm items-center py-5 flex"
    >
      <div className="ml-4 md:ml-6">
        <div>
          <img
            data-testid="logoHolder"
            width="150"
            height="100%"
            src="https://res.cloudinary.com/hyetmyubn/image/upload/v1623680368/Brands%20for%20Good%20Quiz/SB_logo_livegood_h_v2.png"
            alt="company logo"
          />
        </div>
      </div>
    </nav>
  );
}
